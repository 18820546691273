<template>
    <div id="myNFTs">
        <div class="myNFTsList">
            <div class="myNFTs-screen" v-if="searchList.length>0">
                <div class="myNFTs-screen-li" v-for="(item,index1) in searchList" :key="index1" :class="{'myNFTs-screen-li-class': searchClass===item.name}" @click="search(item.name)">{{item.name}} {{item.count}}</div>
            </div>
            <!-- Collected -->
            <el-collapse v-model="activeName" accordion v-if="searchClass==='Collected'">
                <el-collapse-item :title="`Single ${historyList.length}`" name="1" v-if="historyList.length>0">
                    <div class="historyBox">
                        <!-- historyList -->
                        <div class="historyLi" v-for="(item,index2) in historyList" :key="index2">
                            <div @click="goDetails(item)">
                                <div class="history-img"><img :src="item.awsurl?item.awsurl:item.ipfsurl"></div>
                                <div class="history-information-box">
                                    <div class="history-information1">
                                        <div class="history-name">{{item.title}}</div>
                                        <div class="history-price">
                                            <div class="history-price-logo"><img :src="chainImg[chainId]"></div>
                                            <div>{{item.price}}</div>
                                        </div>
                                    </div>
                                    <div class="history-information2">
                                        <div class="history-id">#{{total - index2}}</div>
                                        <div class="history-time">{{item.timeago}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="history-actionBar" v-if="item.state === null || item.state === ''">
                                <div class="history-button" @click="goTransaction(item,'sell')">Sell</div>
                                <div class="history-button" @click="goTransaction(item,'Transfer')">Transfer</div>
                            </div>
                            <div class="history-actionBar" v-else-if="item.state === 'sale'">
                                <div class="history-button" @click="cancelSale(item)">Cancel Listing</div>
                            </div>
                        </div>
                        <div class="lookMore" @click="moreHistory(1)" v-if="searchClass==='Collected'&&historyList.length!==0 && (historyList.length === historyListQuery1.pagenum * historyListQuery1.pagesize) && (total > historyListQuery1.pagenum * historyListQuery1.pagesize)">more</div>
                    </div>
                </el-collapse-item>
            </el-collapse>
            <el-collapse v-model="activeName1" accordion v-if="searchClass==='Collected'">
                <el-collapse-item :title="`Series ${nftsList.length}`" name="1" v-if="nftsList.length>0">
                    <div class="historyBox">
                        <!-- newNFT -->
                        <div class="historyLi2" v-for="(item1,index3) in nftsList" :key="'historyLi2-'+index3">
                            <div @click="goDetails1(item1)">
                                <div class="history-img"><img v-lazy="item1.awsurl?item1.awsurl:item1.ipfsurl"></div>
                                <div class="history-information-box">
                                    <div class="history-information1">
                                        <div class="history-name">{{item1.title}}</div>
                                        <div class="history-price">
                                            <div class="history-price-logo"><img :src="chainImg[chainId]"></div>
                                            <div>{{item1.price}}</div>
                                        </div>
                                    </div>
                                    <div class="history-information2">
                                        <div class="history-id" v-if="item1.state != 'sale'">round {{totalCount - index3}}</div>
                                        <div class="history-id" v-if="item1.state === 'sale'">{{item1.description}}</div>
                                        <div class="progress-count" v-if="item1.state != 'sale'">Progress {{item1.uploadcount == 'null'?'0':item1.uploadcount}}/{{item1.maxcount=='null'?'0':item1.maxcount}}</div>
                                        <div class="progress-count" v-if="item1.state == 'sale'"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="history-actionBar" v-if="item1.state === null || item1.state === ''">
                                <div class="history-button" @click="goTransaction(item1,'sell')">Sell</div>
                                <div class="history-button" @click="goTransaction(item1,'Transfer')">Transfer</div>
                            </div>
                            <div class="history-actionBar" v-else-if="item1.state === 'sale'">
                                <div class="history-button" @click="cancelSaleNew(item1)">Cancel Listing</div>
                            </div>
                        </div>
                        <div class="lookMore" @click="moreHistory(2)" v-if="searchClass==='Collected' && nftsList.length!==0 && (nftsList.length === historyListQuery2.pagenum * historyListQuery2.pagesize) && (totalCount > historyListQuery2.pagenum * historyListQuery2.pagesize)">more</div>
                    </div>
                </el-collapse-item>
            </el-collapse>
            <!-- Created  -->
            <div class="historyBox" v-infinite-scroll="scrollBtn" infinite-scroll-disabled="busy" infinite-scroll-distance="50" v-show="searchClass==='Created'">
                <div id="seriesBox">
                    <div class="series-list">
                        <div class="series-li" v-for="(item,index4) in createdList" :key="index4" @click="goMintList(item.seriesid,item.admin,item.tokenaddress)">
                            <div class="series-list-img-box">
                                <div class="series-list-img1"><img :src="`https://ipfs.io/ipfs/${item.serieslogo}`"></div>
                                <div class="series-list-img-box1">
                                    <img :src="`https://ipfs.io/ipfs/${item.seriesbanner}`" class="series-list-img2">
                                </div>
                            </div>
                            <div class="series-list-text-box">
                                <div class="series-list-name">{{item.seriesname}}</div>
                                <div class="series-list-name1"><span class="by-name">by</span><span class="name-info">{{seriesAdmin}}</span></div>
                                <div class="series-list-text">{{item.description}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="lookMore" @click="moreHistory(3)" v-if="searchClass==='Created'&&createdList.length!==0 && (createdList.length === historyListQuery3.pagenum * historyListQuery3.pagesize) && (totlaCount1 > historyListQuery3.pagenum * historyListQuery3.pagesize)">more</div> -->
            </div>
            <el-collapse v-model="activeName2" accordion v-if="searchClass==='On sale'">
                <el-collapse-item :title="`Single ${historyList1.length}`" name="1" v-if="historyList1.length>0">
                    <div class="historyBox">
                        <div class="historyLi3" v-for="(item,index5) in historyList1" :key="'historyLi3-'+index5">
                            <div @click="goDetails(item)">
                                <div class="history-img"><img :src="item.awsurl?item.awsurl:item.ipfsurl"></div>
                                <div class="history-information-box">
                                    <div class="history-information1">
                                        <div class="history-name">{{item.title}}</div>
                                        <div class="history-price">
                                            <div class="history-price-logo"><img :src="chainImg[chainId]"></div>
                                            <div>{{item.price}}</div>
                                        </div>
                                    </div>
                                    <div class="history-information2">
                                        <div class="history-id">#{{total1 - index5}}</div>
                                        <div class="history-time">{{item.timeago}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="history-actionBar" v-if="item.state === null || item.state === ''">
                                <div class="history-button" @click="goTransaction(item,'sell')">Sell</div>
                                <div class="history-button" @click="goTransaction(item,'Transfer')">Transfer</div>
                            </div>
                            <div class="history-actionBar" v-else-if="item.state === 'sale'">
                                <div class="history-button" @click="cancelSale1(item)">Cancel Listing</div>
                            </div>
                        </div>
                        <div class="lookMore" @click="moreHistory(4)" v-if="searchClass==='On sale'&&historyList1.length!==0 && (historyList1.length === historyListQuery4.pagenum * historyListQuery4.pagesize) && (total1 > historyListQuery4.pagenum * historyListQuery4.pagesize)">more</div>
                    </div>
                </el-collapse-item>
            </el-collapse>
            <el-collapse v-model="activeName3" accordion v-if="searchClass==='On sale'">
                <el-collapse-item :title="`Series ${nftsList1.length}`" name="1" v-if="nftsList1.length>0">
                    <div class="historyBox">
                        <!-- newNFT -->
                        <div class="historyLi4" v-for="(item2,index6) in nftsList1" :key="'historyLi4-'+index6">
                            <div @click="goDetails1(item2)">
                                <div class="history-img"><img :src="item2.awsurl?item2.awsurl:item2.ipfsurl"></div>
                                <div class="history-information-box">
                                    <div class="history-information1">
                                        <div class="history-name">{{item2.title}}</div>
                                        <div class="history-price">
                                            <div class="history-price-logo"><img :src="chainImg[chainId]"></div>
                                            <div>{{item2.price}}</div>
                                        </div>
                                    </div>
                                    <div class="history-information2">
                                        <div class="history-id">round {{totalCount2 - index6}}</div>
                                        <div class="progress-count">Progress {{item2.uploadcount == 'null'?'0':item2.uploadcount}}/{{item2.maxcount=='null'?'0':item2.maxcount}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="history-actionBar" v-if="item2.state === null || item2.state === ''">
                                <div class="history-button" @click="goTransaction(item2,'sell')">Sell</div>
                                <div class="history-button" @click="goTransaction(item2,'Transfer')">Transfer</div>
                            </div>
                            <div class="history-actionBar" v-else-if="item2.state === 'sale'">
                                <div class="history-button" @click="cancelSaleNew(item2)">Cancel Listing</div>
                            </div>
                        </div>
                        <div class="lookMore" @click="moreHistory(5)" v-if="searchClass==='On sale'&&nftsList1.length!==0 && (nftsList1.length === historyListQuery5.pagenum * historyListQuery5.pagesize) && (totlaCount2 > historyListQuery5.pagenum * historyListQuery5.pagesize)">more</div>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </div>
        <!-- <div class="myNFTsOptions" v-else>
      <Transaction v-bind:data="itemData"/>
    </div> -->
    </div>
</template>
<script>
import { queryHistoryMarket } from "../../web3/HistoryMarket";
import { chainName, chainImg, networkName } from "../../web3/web3Config";
import { getChainIdNetwork } from "../../web3/common/wallet";
const wallet = require('../../web3/common/wallet.js');
const address = require('../../web3/web3Config.js');
import infiniteScroll from "vue-infinite-scroll"
export default {
    name: "myNFTs",
    inject: ['reload'],
    directives: { infiniteScroll },
    data() {
        return {
            chainImg: chainImg,
            baseUrlData: '',
            activeName: '1',
            activeName1: '1',
            activeName2: '1',
            activeName3: '1',
            searchList: [],
            searchClass: {},
            historyListQuery1: {
                pagenum: 1,
                pagesize: 4,
            },
            historyListQuery2: {
                pagenum: 1,
                pagesize: 4,
            },
            historyListQuery3: {
                pagenum: 1,
                pagesize: 4,
            },
            historyListQuery4: {
                pagenum: 1,
                pagesize: 4,
            },
            historyListQuery5: {
                pagenum: 1,
                pagesize: 4,
            },
            historyList: [],
            historyList1: [],
            nftsList: [],
            nftsList1: [],
            createdList: [],
            seriesAdmin: '',
            total: '',
            total1: '',
            totalCount: '',
            totlaCount1: '',
            totlaCount2: '',
            itemData: {},
            arrayList: [],
            chainName: chainName,
            HistoryERC721: '',
            HistorySeriesERC721: '',
            networkIsShow: '',
            busy: false,
        }
    },
    computed: {
        mapState() {
            return this.$store.getters.walletInfo
        },
        chainId() {
            return this.$store.getters.walletInfo.chainId;
        },
        networkShow() {
            return this.$store.getters.walletInfo.network;
        },
    },
    watch: {
        '$store.getters.initStatusComplete': {
            immediate: true,
            handler(flag) {
                if (flag) {
                    this.init();
                }
            }
        }
    },
    // components: {
    //   'Transaction': () => import('./transaction.vue'),
    // },
    mounted() {},
    methods: {
        async init() {
            this.baseUrlData = localStorage.getItem('baseUrl')

            let res = '';
            if (this.mapState.chainId) {
                res = this.mapState.chainId
            } else {
                res = await getChainIdNetwork();
            }

            this.HistoryERC721 = address.address[this.chainName[res]].HistoryERC721;
            this.HistorySeriesERC721 = address.address[this.chainName[res]].HistorySeriesERC721;
            this.networkIsShow = networkName[res]
            if (this.$route.query.myNFTsItem || this.$route.query.transactionState) {
                this.goTransaction(JSON.parse(this.$route.query.myNFTsItem), this.$route.query.transactionState);
            } else {
                this.querySearchList();
                this.queryHistory();
                this.queryGetseriesnfts()
            }



        },


        scrollBtn() {
            if (this.createdList.length === this.historyListQuery3.pagenum * this.historyListQuery3.pagesize && this.totlaCount1 > this.historyListQuery3.pagenum * this.historyListQuery3.pagesize) {
                this.historyListQuery3.pagenum += 1
                this.queryGetseriesinfos()
            }
        },
        search(value) {
            this.searchClass = value;
            if (this.searchClass == 'Created') {
                this.historyListQuery3.pagenum = 1
                this.historyListQuery3.pagesize = 4
                this.createdList = []
                this.queryGetseriesinfos()
            }
            if (this.searchClass == 'On sale') {
                this.historyList1 = [],
                    this.nftsList1 = [],
                    this.queryHistory1();
                this.queryGetseriesnfts1()
            }
        },
        moreHistory(value) {
            if (value == 1) {
                this.historyListQuery1.pagenum += 1;
                this.queryHistory()
            } else if (value == 2) {
                this.historyListQuery2.pagenum += 1;
                this.queryGetseriesnfts()
            }
            // else if(value == 3){
            //   this.historyListQuery3.pagenum += 1;
            //   this.queryGetseriesinfos()
            // }
            else if (value == 4) {
                this.historyListQuery4.pagenum += 1;
                this.queryHistory1()
            } else if (value == 5) {
                this.historyListQuery5.pagenum += 1;
                this.queryGetseriesnfts1()
            }
        },
        goTransaction(item, view) {
            this.itemData = {
                tokenid: item.tokenid,
                price: 1,
                currentPage: 2,
            }
            let transactionState = view
            let historyItem = item
            let tokenaddress = item.tokenaddress
            this.$router.push({ path: '/transaction', query: { itemData: JSON.stringify(this.itemData), item: JSON.stringify(historyItem), transactionState: transactionState, tokenaddress: tokenaddress } })
        },
        goMintList(id, admin, tokenAddress) {
            let isShow = false
            this.$router.push({ path: '/series', query: { id, isShow, admin, tokenAddress } })
        },
        querySearchList() {
            let _this = this;
            if (this.mapState.chainId) {
                wallet.getWalletInfo().then((info) => {
                    let params = {
                        admin: info.address.toLowerCase(),
                        network: this.networkShow ? this.networkShow : this.networkIsShow
                    };
                    this.$axios.get(this.baseUrlData + this.$api.post_history_searchList, params).then(res => {
                        if (!params.network) {
                            return;
                        }
                        _this.searchList = res.result;
                        _this.searchClass = res.result[0].name;
                    })
                })
            }
        },
        cancelSale(item) {
            let _this = this;
            let erc21 = queryHistoryMarket();
            if (this.mapState.chainId) {
                wallet.getWalletInfo().then((info) => {
                    erc21.sellNft(this.HistoryERC721, item.tokenid, 0).then(() => {
                        let params = {
                            admin: info.address.toLowerCase(),
                            tokenid: item.tokenid
                        };
                        this.$axios.post(this.baseUrlData + this.$api.post_cancelSale, params).then(() => {
                            this.$message({
                                message: 'Operation succeeded',
                                type: 'success'
                            });
                            _this.reload()
                        })
                    })
                })
            }
        },
        cancelSale1(item) {
            let _this = this;
            let erc21 = queryHistoryMarket();
            if (this.mapState.chainId) {
                wallet.getWalletInfo().then((info) => {
                    erc21.sellNft(this.HistoryERC721, item.tokenid, 0).then(() => {
                        let params = {
                            admin: info.address.toLowerCase(),
                            tokenid: item.tokenid
                        };
                        this.$axios.post(this.baseUrlData + this.$api.post_cancelSale, params).then(() => {
                            this.$message({
                                message: 'Operation succeeded',
                                type: 'success'
                            });
                            _this.queryHistory();
                        })
                    })
                })
            }
        },
        cancelSaleNew(item) {
            let _this = this;
            let erc21 = queryHistoryMarket();
            if (this.mapState.chainId) {
                wallet.getWalletInfo().then((info) => {
                    erc21.sellNft(this.querytokenaddress == this.HistorySeriesERC721 ? this.HistorySeriesERC721 : item.tokenaddress, item.tokenid, 0).then(() => {
                        let params = {
                            admin: info.address.toLowerCase(),
                            tokenid: item.tokenid,
                            tokenaddress: item.tokenaddress
                        };
                        this.$axios.post(this.baseUrlData + this.$api.post_cancelseriessale, params).then(() => {
                            this.$message({
                                message: 'Operation succeeded',
                                type: 'success'
                            });
                            _this.reload()
                        })
                    })
                })
            }
        },
        // coolected - 1
        queryHistory() {
            let _this = this;
            if (this.mapState.chainId) {
                wallet.getWalletInfo().then((info) => {
                    let params = {
                        admin: info.address.toLowerCase(),
                        network: this.networkShow ? this.networkShow : this.networkIsShow,
                        pagenum: this.historyListQuery1.pagenum,
                        pagesize: this.historyListQuery1.pagesize,
                        state: '',
                        tokenaddress: this.HistoryERC721,
                    };
                    this.$axios.get(this.baseUrlData + this.$api.post_historyList, params).then(res => {
                        if (!params.network) {
                            return;
                        }
                        _this.historyList = _this.historyList.concat(res.result.list)
                        _this.total = res.result.total;
                    })
                })
            }
        },
        queryHistory1() {
            let _this = this;
            if (this.mapState.chainId) {
                wallet.getWalletInfo().then((info) => {
                    let params = {
                        admin: info.address.toLowerCase(),
                        network: this.networkShow ? this.networkShow : this.networkIsShow,
                        pagenum: this.historyListQuery1.pagenum,
                        pagesize: this.historyListQuery1.pagesize,
                        state: 'sale',
                        tokenaddress: this.HistoryERC721,
                    };
                    this.$axios.get(this.baseUrlData + this.$api.post_historyList, params).then(res => {
                        if (!params.network) {
                            return;
                        }
                        _this.historyList1 = _this.historyList1.concat(res.result.list)
                        _this.total1 = res.result.total;
                    })
                })
            }
        },
        // collected-2
        queryGetseriesnfts() {
            if (this.mapState.chainId) {
                wallet.getWalletInfo().then((info) => {
                    var params = {
                        pagenum: this.historyListQuery2.pagenum,
                        pagesize: this.historyListQuery2.pagesize,
                        network: this.networkShow ? this.networkShow : this.networkIsShow,
                        admin: info.address.toLowerCase(),
                        state: '',
                        tokenaddress: '',
                    };
                    this.$axios.get(this.baseUrlData + this.$api.post_getseriesnftsadmin, params).then((res) => {
                        if (!params.network) {
                            return;
                        }
                        this.nftsList = this.nftsList.concat(res.result.list)
                        this.totalCount = res.result.total;
                    })
                })
            }
        },
        queryGetseriesnfts1() {
            let _this = this;
            if (this.mapState.chainId) {
                wallet.getWalletInfo().then((info) => {
                    let params = {
                        pagenum: this.historyListQuery5.pagenum,
                        pagesize: this.historyListQuery5.pagesize,
                        network: this.networkShow ? this.networkShow : this.networkIsShow,
                        seriesid: '',
                        admin: info.address.toLowerCase(),
                        state: 'sale',
                        tokenaddress: '',
                    };
                    this.$axios.get(this.baseUrlData + this.$api.post_getseriesnftsadmin, params).then((res) => {
                        if (!params.network) {
                            return;
                        }
                        _this.nftsList1 = _this.nftsList1.concat(res.result.list)
                        _this.totalCount2 = res.result.total;
                    })
                })
            }
        },
        queryGetseriesinfos() {
            if (this.mapState.chainId) {
                wallet.getWalletInfo().then((info) => {
                    let params = {
                        admin: info.address.toLowerCase(),
                        pagenum: this.historyListQuery3.pagenum,
                        pagesize: this.historyListQuery3.pagesize,
                        network: this.networkShow ? this.networkShow : this.networkIsShow,
                    };
                    this.$axios.get(this.baseUrlData + this.$api.post_getseriesinfos, params).then((res) => {
                        if (!params.network) {
                            return;
                        }
                        this.createdList = this.createdList.concat(res.result.list)
                        this.totlaCount1 = res.result.total
                        res.result.list.forEach((item) => {
                            let address = item.admin;
                            let startText = address.substring(0, 5);
                            let endText = address.substring(address.length - 4, address.length);
                            this.seriesAdmin = startText + '...' + endText;
                        })
                    })
                })
            }
        },
        goDetails(item) {
            this.$router.push({
                path: `/history/?tokenId=${item.tokenid}&tokenaddress=${item.tokenaddress}&actionsState=false`,
                // path: `/history/?tokenId=${item.tokenid}&tokenaddress=${item.tokenaddress}&actionsState=false&historyItem=${JSON.stringify(item)}`,
            })
        },
        goDetails1(item) {
            this.$router.push({
                path: `/history/?tokenIdData=${item.tokenid}&tokenaddress=${item.tokenaddress}&actionsState=false`,
                // path: `/history/?tokenIdData=${item.tokenid}&tokenaddress=${item.tokenaddress}&actionsState=false&historyItem=${JSON.stringify(item)}`,
            })
        },
    },
}
</script>
<style lang="scss" scoped>
#myNFTs {
    // padding-left: 1.84rem;
    padding: 0 0.8rem .8rem 0.8rem;
    margin-top: 140px;

    .myNFTs-screen {
        // width: 15.52rem;
        border-bottom: 1px solid #E6E6E6;
        display: flex;
        justify-content: center;
        margin-top: 1rem;
        margin-block: 10px;

        .myNFTs-screen-li {
            cursor: pointer;
            width: 1.46rem;
            font-family: Helvetica;
            font-style: normal;
            font-weight: bold;
            font-size: .23rem;
            color: #707A83;
            margin-left: 0.28rem;
            text-align: center;
            padding-bottom: 0.27rem;
        }

        .myNFTs-screen-li:nth-of-type(1) {
            margin-left: 0;
        }

        .myNFTs-screen-li-class {
            border-bottom: 2px solid #FFE161;
            color: #353840;
            font-weight: bold;
        }
    }

    .historyBox {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 10px;
        box-sizing: border-box;

        // margin-bottom: 0.34rem;
        .historyLi {
            margin: 0 0.4rem .38rem 0;
            width: 4rem;
            background: #FFFFFF;
            border-radius: 0.1rem;
            cursor: pointer;
            border: 1px solid #E5E8EB;

            .history-img {
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                border-radius: 0.1rem 0.1rem 0 0;

                img {
                    height: 4rem;
                    border-radius: 0.1rem 0.1rem 0 0;
                }
            }

            .history-information-box {
                box-shadow: 0 0 8px 0 rgba(4, 17, 29, 0.15);
                border-radius: 0;

                .history-information1 {
                    font-size: 0.12rem;
                    font-family: Poppins-SemiBold, Poppins;
                    font-weight: 600;
                    color: #353840;
                    line-height: 0.18rem;
                    display: flex;
                    padding: 0.12rem 0.12rem 0 0.12rem;

                    .history-name {
                        max-width: 2.28rem;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    .history-price {
                        margin-left: auto;
                        font-family: Poppins-Medium, Poppins;
                        font-weight: 500;
                        color: #000000;
                        line-height: 0.21rem;
                        // text-shadow: 0 0 0.08rem rgba(4, 17, 29, 0.25);
                        font-size: 0.14rem;
                        max-width: 1rem;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        display: flex;

                        .history-price-logo {
                            margin-right: 0.07rem;

                            img {
                                width: 0.1rem;
                                height: 0.14rem;
                                object-fit: cover;
                            }
                        }
                    }

                    .history-price div:nth-of-type(2) {
                        margin-top: -0.015rem;
                    }
                }

                .history-information2 {
                    padding: 0.1rem 0.12rem 0.24rem 0.12rem;
                    display: flex;
                    font-size: 0.12rem;
                    font-family: Poppins-Regular, Poppins;
                    font-weight: 400;
                    color: #707A83;
                    line-height: 0.18rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .history-id {
                        max-width: 1.6rem;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    .history-time {
                        margin-left: auto;
                    }
                }
            }

            .history-actionBar {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-end;
                height: 0.55rem;
                border-radius: 0 0 0.1rem 0.1rem;

                .history-button {
                    display: none;
                    //width: 0.72rem;
                    margin: 0.20rem 0.12rem 0.12rem 0.12rem;
                    font-size: 0.16rem;
                    font-family: Poppins-Bold, Poppins;
                    font-weight: bold;
                    color: #FFC107;
                    line-height: 0.25rem;
                    // text-shadow: 0 0 0.08rem rgba(4, 17, 29, 0.25);
                }

                .history-button:nth-of-type(2) {
                    margin-left: auto;
                    text-align: right;
                }

                .optionsState {
                    opacity: 0.2;
                    cursor: no-drop;
                }

                .optionsState:hover {
                    background: #FFFFFF !important;
                    color: #FFE161 !important;
                }
            }
        }

        .historyLi2 {
            margin: 0 0.4rem .38rem 0;
            width: 4rem;
            background: #FFFFFF;
            border-radius: 0.1rem;
            cursor: pointer;
            border: 1px solid #E5E8EB;

            .history-img {
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                border-radius: 0.1rem 0.1rem 0 0;

                img {
                    height: 4rem;
                    border-radius: 0.1rem 0.1rem 0 0;
                }
            }

            .history-information-box {
                box-shadow: 0 0 8px 0 rgba(4, 17, 29, 0.15);
                border-radius: 0 0 0.1rem 0.1rem;

                .history-information1 {
                    font-size: 0.12rem;
                    font-family: Poppins-SemiBold, Poppins;
                    font-weight: 600;
                    color: #353840;
                    line-height: 0.18rem;
                    display: flex;
                    padding: 0.12rem 0.12rem 0 0.12rem;

                    .history-name {
                        max-width: 3.8rem;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    .history-price {
                        margin-left: auto;
                        font-family: Poppins-Medium, Poppins;
                        font-weight: 500;
                        color: #000000;
                        line-height: 0.21rem;
                        // text-shadow: 0 0 0.08rem rgba(4, 17, 29, 0.25);
                        font-size: 0.14rem;
                        max-width: 1rem;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        display: flex;

                        .history-price-logo {
                            margin-right: 0.07rem;

                            img {
                                width: 0.1rem;
                                height: 0.14rem;
                                object-fit: cover;
                            }
                        }
                    }

                    .history-price div:nth-of-type(2) {
                        margin-top: -0.015rem;
                    }
                }

                .history-information2 {
                    padding: 0.1rem 0.12rem 0.24rem 0.12rem;
                    display: flex;
                    font-size: 0.12rem;
                    font-family: Poppins-Regular, Poppins;
                    font-weight: 400;
                    color: #707A83;
                    line-height: 0.18rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .history-id {
                        max-width: 1.6rem;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    .history-time {
                        margin-left: auto;
                    }
                }
            }

            .history-actionBar {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-end;
                height: 0.55rem;
                border-radius: 0 0 0.1rem 0.1rem;

                .history-button {
                    display: none;
                    //width: 0.72rem;
                    margin: 0.20rem 0.12rem 0.12rem 0.12rem;
                    font-size: 0.16rem;
                    font-family: Poppins-Bold, Poppins;
                    font-weight: bold;
                    color: #FFC107;
                    line-height: 0.25rem;
                    // text-shadow: 0 0 0.08rem rgba(4, 17, 29, 0.25);
                }

                .history-button:nth-of-type(2) {
                    margin-left: auto;
                    text-align: right;
                }

                .optionsState {
                    opacity: 0.2;
                    cursor: no-drop;
                }

                .optionsState:hover {
                    background: #FFFFFF !important;
                    color: #FFE161 !important;
                }
            }

            .progress-count {
                font-size: 12px;
                color: #707a83;
            }
        }

        .historyLi3 {
            margin: 0 0.4rem .38rem 0;
            width: 4rem;
            background: #FFFFFF;
            border-radius: 0.1rem;
            cursor: pointer;
            border: 1px solid #E5E8EB;

            .history-img {
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                border-radius: 0.1rem 0.1rem 0 0;

                img {
                    height: 4rem;
                    border-radius: 0.1rem 0.1rem 0 0;
                }
            }

            .history-information-box {
                box-shadow: 0 0 8px 0 rgba(4, 17, 29, 0.15);
                border-radius: 0 0 0.1rem 0.1rem;

                .history-information1 {
                    font-size: 0.12rem;
                    font-family: Poppins-SemiBold, Poppins;
                    font-weight: 600;
                    color: #353840;
                    line-height: 0.18rem;
                    display: flex;
                    padding: 0.12rem 0.12rem 0 0.12rem;

                    .history-name {
                        max-width: 3.8rem;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    .history-price {
                        margin-left: auto;
                        font-family: Poppins-Medium, Poppins;
                        font-weight: 500;
                        color: #000000;
                        line-height: 0.21rem;
                        // text-shadow: 0 0 0.08rem rgba(4, 17, 29, 0.25);
                        font-size: 0.14rem;
                        max-width: 1rem;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        display: flex;

                        .history-price-logo {
                            margin-right: 0.07rem;

                            img {
                                width: 0.1rem;
                                height: 0.14rem;
                                object-fit: cover;
                            }
                        }
                    }

                    .history-price div:nth-of-type(2) {
                        margin-top: -0.015rem;
                    }
                }

                .history-information2 {
                    padding: 0.1rem 0.12rem 0.24rem 0.12rem;
                    display: flex;
                    font-size: 0.12rem;
                    font-family: Poppins-Regular, Poppins;
                    font-weight: 400;
                    color: #707A83;
                    line-height: 0.18rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .history-id {
                        max-width: 1.6rem;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    .history-time {
                        margin-left: auto;
                    }
                }
            }

            .history-actionBar {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-end;
                height: 0.55rem;
                border-radius: 0 0 0.1rem 0.1rem;

                .history-button {
                    display: none;
                    //width: 0.72rem;
                    margin: 0.20rem 0.12rem 0.12rem 0.12rem;
                    font-size: 0.16rem;
                    font-family: Poppins-Bold, Poppins;
                    font-weight: bold;
                    color: #FFC107;
                    line-height: 0.25rem;
                    // text-shadow: 0 0 0.08rem rgba(4, 17, 29, 0.25);
                }

                .history-button:nth-of-type(2) {
                    margin-left: auto;
                    text-align: right;
                }

                .optionsState {
                    opacity: 0.2;
                    cursor: no-drop;
                }

                .optionsState:hover {
                    background: #FFFFFF !important;
                    color: #FFE161 !important;
                }
            }

            .progress-count {
                font-size: 12px;
                color: #707a83;
            }
        }

        .historyLi4 {
            margin: 0 0.4rem .38rem 0;
            width: 4rem;
            background: #FFFFFF;
            border-radius: 0.1rem;
            cursor: pointer;
            border: 1px solid #E5E8EB;

            .history-img {
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                border-radius: 0.1rem 0.1rem 0 0;

                img {
                    height: 4rem;
                    border-radius: 0.1rem 0.1rem 0 0;
                }
            }

            .history-information-box {
                box-shadow: 0 0 8px 0 rgba(4, 17, 29, 0.15);
                border-radius: 0 0 0.1rem 0.1rem;

                .history-information1 {
                    font-size: 0.12rem;
                    font-family: Poppins-SemiBold, Poppins;
                    font-weight: 600;
                    color: #353840;
                    line-height: 0.18rem;
                    display: flex;
                    padding: 0.12rem 0.12rem 0 0.12rem;

                    .history-name {
                        max-width: 3.8rem;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    .history-price {
                        margin-left: auto;
                        font-family: Poppins-Medium, Poppins;
                        font-weight: 500;
                        color: #000000;
                        line-height: 0.21rem;
                        // text-shadow: 0 0 0.08rem rgba(4, 17, 29, 0.25);
                        font-size: 0.14rem;
                        max-width: 1rem;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        display: flex;

                        .history-price-logo {
                            margin-right: 0.07rem;

                            img {
                                width: 0.1rem;
                                height: 0.14rem;
                                object-fit: cover;
                            }
                        }
                    }

                    .history-price div:nth-of-type(2) {
                        margin-top: -0.015rem;
                    }
                }

                .history-information2 {
                    padding: 0.1rem 0.12rem 0.24rem 0.12rem;
                    display: flex;
                    font-size: 0.12rem;
                    font-family: Poppins-Regular, Poppins;
                    font-weight: 400;
                    color: #707A83;
                    line-height: 0.18rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .history-id {
                        max-width: 1.6rem;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    .history-time {
                        margin-left: auto;
                    }
                }
            }

            .history-actionBar {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-end;
                height: 0.55rem;
                border-radius: 0 0 0.1rem 0.1rem;

                .history-button {
                    display: none;
                    //width: 0.72rem;
                    margin: 0.20rem 0.12rem 0.12rem 0.12rem;
                    font-size: 0.16rem;
                    font-family: Poppins-Bold, Poppins;
                    font-weight: bold;
                    color: #FFC107;
                    line-height: 0.25rem;
                    // text-shadow: 0 0 0.08rem rgba(4, 17, 29, 0.25);
                }

                .history-button:nth-of-type(2) {
                    margin-left: auto;
                    text-align: right;
                }

                .optionsState {
                    opacity: 0.2;
                    cursor: no-drop;
                }

                .optionsState:hover {
                    background: #FFFFFF !important;
                    color: #FFE161 !important;
                }
            }

            .progress-count {
                font-size: 12px;
                color: #707a83;
            }
        }

        .historyLi:hover {
            box-shadow: 0 0 8px 0 rgba(4, 17, 29, 0.15);

            .history-button {
                display: block;
            }
        }

        .historyLi2:hover {
            box-shadow: 0 0 8px 0 rgba(4, 17, 29, 0.15);

            .history-button {
                display: block;
            }
        }

        .historyLi3:hover {
            box-shadow: 0 0 8px 0 rgba(4, 17, 29, 0.15);

            .history-button {
                display: block;
            }
        }

        .historyLi4:hover {
            box-shadow: 0 0 8px 0 rgba(4, 17, 29, 0.15);

            .history-button {
                display: block;
            }
        }
    }
}

.buttonHover1:hover {
    opacity: 0.8;
}

.buttonHover2:hover {
    background: #FFE161;
    color: #FFFFFF !important;
}

#seriesBox {
    .series-list {
        display: flex;
        flex-wrap: wrap;

        .series-li:hover {
            box-shadow: rgb(4 17 29 / 25%) 0px 0px 8px 0px;
            transition: all 0.1s ease 0s;
            border: none;
        }

        .series-li {
            width: 4rem;
            height: 4rem;
            margin: 0.38rem 0.4rem 0 0;
            // border: 1px solid #E5E8EB;
            box-shadow: rgb(4 17 29 / 25%) 0px 0px 2px 0px;
            border-radius: .1rem;
            border-top: none;
            cursor: pointer;

            .series-list-img-box {
                width: 100%;
                height: 1.99rem;
                border-radius: .1rem .1rem 0px 0px;
                position: relative;
            }

            .series-list-text-box {
                // border: 1px solid #E5E8EB;
                // border-top: none;
                width: 100%;
                height: 1.99rem;
            }

            .series-list-img-box1 {
                position: absolute;
                bottom: -.25rem;
                left: 50%;
                transform: translate(-50%);
                width: .5rem;
                height: .5rem;
                background: #FBFDFF;
                border: 3px solid #FBFDFF;
                box-sizing: border-box;
                box-shadow: 0px 0px 2px rgba(14, 14, 14, 0.6);
                border-radius: 24px;
            }

            .series-list-img2 {
                display: block;
                width: .44rem;
                height: .44rem;
                border-radius: .22rem;
            }

            .series-list-img1 {
                display: flex;
                justify-content: center;

                img {
                    border-radius: .1rem .1rem 0px 0px;
                    width: 100%;
                    height: 1.99rem;
                    object-fit: cover;
                }
            }

            .series-list-name {
                font-size: 0.16rem;
                font-family: Poppins-Bold, Poppins;
                font-weight: bold;
                color: #000000;
                // line-height: 0.25rem;
                text-align: center;
                padding-top: 0.34rem;
                box-sizing: border-box;
            }

            .series-list-name1 {
                font-size: 0.14rem;
                font-family: Poppins-Bold, Poppins;
                font-weight: bold;
                text-align: center;
                box-sizing: border-box;
                color: #707A83;
                margin-top: 2px;

                .by-name {
                    margin-right: 4px;
                }

                .name-info {
                    color: #FFC107;
                }
            }

            .series-list-text {
                width: 3.35rem;
                height: .65rem;
                margin: 0 auto;
                font-size: 0.16rem;
                font-family: PingFangTC-Regular, PingFangTC;
                font-weight: 400;
                color: #707A83;
                line-height: 0.22rem;
                margin-top: 0.24rem;
                text-align: center;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
            }
        }

        .series-li:nth-of-type(1) {
            margin-left: 0;
        }
    }
}

.lookMore {
    width: 1.93rem;
    height: 0.56rem;
    border: 1px solid #FFE161;
    box-sizing: border-box;
    border-radius: 0.15rem;
    font-family: Helvetica;
    font-style: normal;
    font-weight: normal;
    font-size: 0.19rem;
    line-height: 0.54rem;
    text-align: center;
    color: #614F00;
    margin: 0.34rem auto;
    background-color: #FFE161;
    cursor: pointer;
    font-family: Poppins-Bold, Poppins;
    font-weight: bold;
}

.lookMore:hover {
    box-shadow: #FFC107 0px 0px 2px 0px;
    transition: all 0.1s ease 0s;
}
</style>
<style>
/*Message prompt*/
.el-message {
    height: 48px !important;
    background: #FFFFFF !important;
    box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.12), 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 4px 16px 4px rgba(0, 0, 0, 0.04);
}

.el-message__icon {
    font-size: 20px;
}

.el-message--success {
    border: none;
}

.el-message--warning {
    border: none;
}

.el-message--error {
    border: none;
}

#myNFTs .el-collapse {
    border: none;
}

#myNFTs .el-collapse-item__header {
    border-bottom: none;
    display: block;
    color: #707A83;
    margin-bottom: 5px;
}

#myNFTs .el-collapse-item__wrap {
    border-bottom: none;
}

#myNFTs .el-collapse-item__content {
    padding-bottom: 0;
}

#myNFTs .el-collapse-item__arrow {
    margin-left: 10px;
}
</style>
<style lang="scss" scoped>
@media (max-width: 1125px) {
    #myNFTs {
        margin: 3.68641rem 0 0;
        padding: .512rem 0 0;

        .myNFTsList {
            .myNFTs-screen {
                margin: 0;
                justify-content: space-between;
                padding: 0 .512rem;


                .myNFTs-screen-li {
                    margin: 0;
                    padding: 0 .512rem;
                    width: auto;
                    height: 2.86721rem;
                    line-height: 2.66241rem;
                    position: relative;
                    border: 0;

                    /*font-family: 'Poppins';*/
                    font-style: normal;
                    font-weight: 600;
                    font-size: .8192rem;
                    /* identical to box height, or 150% */
                    color: #707A83;


                    &.myNFTs-screen-li-class {
                        color: #353840;

                        &:after {
                            content: "";
                            position: absolute;
                            bottom: 0;
                            width: 100%;
                            height: .2048rem;
                            background: #FFE161;
                            border-radius: .512rem .512rem 0px 0px;
                            left: 0;
                        }

                    }
                }
            }

            ::v-deep .el-collapse {
                padding: 0 .768rem;

                .el-collapse-item {
                    .el-collapse-item__header {
                        display: flex;
                        justify-content: space-between;
                        /*font-family: 'Poppins';*/
                        font-style: normal;
                        font-weight: 400;
                        font-size: .8192rem;
                        line-height: 1.2288rem;
                        /* identical to box height, or 150% */
                        color: #353840;
                        margin: 0;
                        height: 2.66241rem;

                        &.is-active {}

                        .el-collapse-item__arrow {
                            font-weight: 900;

                        }




                    }

                    .el-collapse-item__wrap {
                        .el-collapse-item__content {
                            .historyBox {
                                justify-content: space-between;

                                .historyLi,
                                .historyLi2,
                                .historyLi3,
                                .historyLi4 {
                                    width: 8.60162rem;
                                    height: 16.02564rem;
                                    margin: 0 0 .6144rem 0;
                                    border-radius: .4096rem;
                                    overflow: hidden;


                                    .history-img {
                                        img {
                                            // width: 8.60162rem;
                                            height: 9.47202rem;
                                        }

                                    }

                                    .history-information-box {
                                        padding: .4096rem;

                                        .history-information1 {
                                            /*font-family: 'Poppins';*/
                                            font-style: normal;
                                            font-weight: 500;
                                            font-size: .7168rem;
                                            line-height: 1.0752rem;
                                            /* identical to box height */
                                            color: #353840;
                                            padding: 0;
                                            align-items: center;
                                            justify-content: space-between;

                                            display: block;

                                            .history-name {
                                                max-width: 100%;
                                                margin-bottom: .3072rem;
                                            }

                                            .history-price {
                                                margin: 0 0 .3072rem 0;
                                                max-width: none;

                                                /*font-family: 'Poppins';*/
                                                font-style: normal;
                                                font-weight: 500;
                                                font-size: .7168rem;
                                                line-height: 1.0752rem;
                                                /* identical to box height */
                                                text-align: right;
                                                color: #353840;
                                                display: flex;
                                                align-items: center;
                                                // justify-content: center;
                                                width: 100%;



                                                .history-price-logo {
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                    margin-right: .2048rem;

                                                    img {
                                                        width: auto;
                                                        height: .7168rem;
                                                        display: block;
                                                    }
                                                }
                                            }
                                        }

                                        .history-information2 {
                                            /*font-family: 'Poppins';*/
                                            font-style: normal;
                                            font-weight: 400;
                                            font-size: .6144rem;
                                            line-height: .9216rem;
                                            /* identical to box height */
                                            color: #707A83;
                                            padding: 0;

                                            .history-id {
                                                max-width: none;
                                            }

                                            .history-time {
                                                margin: 0;
                                            }
                                        }
                                    }



                                    .history-actionBar {
                                        height: 1.8944rem;
                                        align-items: center;
                                        padding: 0 .4096rem;
                                        justify-content: space-between;


                                        .history-button {
                                            /*font-family: 'Poppins';*/
                                            font-style: normal;
                                            font-weight: 500;
                                            font-size: .7168rem;
                                            line-height: 1.0752rem;
                                            /* identical to box height */
                                            color: #FFC107;
                                            display: block;
                                            margin: 0;
                                        }
                                    }
                                }

                                .lookMore {
                                    width: 17.66405rem;
                                    height: 2.56001rem;
                                    border-radius: .4096rem;
                                    /*font-family: 'Poppins';*/
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: .7168rem;
                                    line-height: 2.56001rem;
                                    /* identical to box height */
                                    text-align: center;
                                    color: #614F00;
                                    margin: 0;
                                }

                                padding-bottom: 1.536rem;
                            }
                        }
                    }

                    &.is-active {}
                }
            }
        }
    }


    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
}
</style>